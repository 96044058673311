import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fileSaver from 'file-saver-es';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { DocumentActions, ServiceConfigurationActions } from 'app/store/actions';
import { AccountType, Document, State, Tenant } from 'app/store/models';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from 'app/services/i18n.service';

@Component({
  selector: 'app-documents-exact',
  templateUrl: './documents-exact.component.html',
  styleUrls: ['./documents-exact.component.scss'],
})
export class DocumentsExactComponent implements OnDestroy {
  loadingDocuments$: Observable<boolean>;
  documents$: Observable<Document[]>;
  linkedTenants$: Observable<Tenant[]>;
  downloadFlags: { [k: string]: boolean } = {};
  currentTenantIsServiceProvider = false;
  currentTenantIsLegalEntity = false;
  userCompany?: string;
  account$ = new BehaviorSubject<AccountType|null>(null);
  accountSubscription: Subscription;
  tenantSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private i18n: I18nService,
  ) {
    this.loadingDocuments$ = this.store.select(x => x.document.loadingItems);

    this.tenantSubscription = this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenantIsServiceProvider = !!tenant.isServiceProvider;
      this.currentTenantIsLegalEntity = !!tenant.isLegalEntity;
      if (this.currentTenantIsLegalEntity) {
        this.account$.next(AccountType.CINVIO);
      } else if (this.currentTenantIsServiceProvider) {
        switch (this.route.snapshot.url[0]?.path) {
          case 'spender':
            this.account$.next(AccountType.SPENDER);
            break;
          case 'earner':
          default:
            this.store.dispatch(ServiceConfigurationActions.loadTenantsForConfigurator());
            this.account$.next(AccountType.EARNER);
        }
      } else {
        if (this.route.snapshot.url[0]?.path === 'earner') {
          this.router.navigateByUrl('/documents/spender');
        } else {
          this.account$.next(AccountType.SPENDER);
        }
      }
    });

    this.documents$ = this.store.select(x => x.document.items).pipe(
      filter(notNull),
      startWith([]),
    );

    this.linkedTenants$ = this.store.select(x => x.serviceConfiguration.tenants).pipe(
      filter(notNull),
      startWith([]),
    );

    // reload documents on account type change
    this.accountSubscription = this.account$.pipe(
      filter(notNull),
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe(accountType => {
      this.store.dispatch(DocumentActions.resetDocuments());
      if (accountType !== AccountType.EARNER) {
        this.store.dispatch(DocumentActions.loadDocuments({}));
      } else if (this.userCompany) {
        this.handleUserCompanyChange(this.userCompany);
      }
    });
  }

  // reload documents on user company change
  handleUserCompanyChange(userCompany?: string) {
    this.userCompany = userCompany;
    if (this.userCompany) {
      this.store.dispatch(DocumentActions.loadDocuments({userCompany}));
    }
  }

  downloadDocument(document: Document) {
    this.downloadFlags[document.id] = true;
    this.api.getBlob({
      path: `/exact_online_documents/${document.id}/download`,
    }).toPromise().then(
      blob => {
        this.downloadFlags[document.id] = false;
        fileSaver.saveAs(blob);
      },
      () => {
        this.downloadFlags[document.id] = false;
      },
    );
  }

  downloadReport(invoiceId: string) {
    this.downloadFlags[invoiceId] = true;

    const timezone = this.i18n.timezone;
    const accountType = this.account$.getValue() ?? AccountType.SPENDER;
    const path = `/transaction_history/download/csv`
        + `?accountType=${encodeURIComponent(accountType)}`
        + `&invoiceId=${encodeURIComponent(invoiceId)}`
        + (timezone !== null ? `&timezone=${encodeURIComponent(timezone)}` : '')

    this.api.getBlob({
      path: path,
    }).toPromise().then(
      blob => {
        this.downloadFlags[invoiceId] = false;
        fileSaver.saveAs(blob, `transactions.csv`);
      },
      () => {
        this.downloadFlags[invoiceId] = false;
      },
    );
  }

  ngOnDestroy() {
    this.accountSubscription.unsubscribe();
    this.tenantSubscription.unsubscribe();
  }
}
