<div *ngIf="currentTenantIsServiceProvider && (account$ | async) === 'EARNER'" class="d-flex flex-row align-items-center flex-wrap mb-3">
  <div class="mb-3">
    <ng-select
      [items]="linkedTenants$ | async"
      bindLabel="name"
      bindValue="id"
      placeholder="{{ 'Select user company' | translate }}"
      [clearable]="true"
      [ngModel]="userCompany"
      (ngModelChange)="handleUserCompanyChange($event)"
    ></ng-select>
  </div>
</div>

<table class="table">
  <tr class="no-border">
    <th>{{ 'Date' | translate }}</th>
    <th>{{ 'Description' | translate }}</th>
    <th>{{ 'Action' | translate }}</th>
  </tr>
  <tr *ngFor="let document of documents$ | async">
    <td>{{ document.invoiceDate | date:'dd-MM-yyyy' }}</td>
    <td>{{ document.description }}</td>
    <td>
      <app-loading-button
        btnType="button"
        btnClass="btn btn-sm btn-link"
        (click)="downloadDocument(document)"
        [isLoading]="downloadFlags[document.id]"
      >
        {{ 'Download' | translate }}
      </app-loading-button>
      <app-loading-button
        *ngIf="document.invoiceId && (account$ | async) === 'SPENDER'"
        btnType="button"
        btnClass="btn btn-sm btn-link"
        (click)="downloadReport(document.invoiceId)"
        [isLoading]="downloadFlags[document.invoiceId]"
      >
        {{ 'Download report' | translate }}
      </app-loading-button>
    </td>
  </tr>
</table>
