<h2>{{ 'Payment methods' | translate }}</h2>
<table class="table">
  <tr>
    <th>{{ 'Payment method' | translate }}</th>
    <th>{{ 'Code' | translate }}</th>
    <th>{{ 'Payment provider' | translate }}</th>
    <th>{{ 'Fixed fee' | translate }}</th>
    <th>{{ 'Percentage fee' | translate }}</th>
    <th>{{ 'Enabled?' | translate }}</th>
    <th></th>
  </tr>
  <tr *ngFor="let paymentMethod of paymentMethods$ | async">
    <td>
      <img width="32" src="/assets/icons/payment/{{paymentMethod.code}}.svg" />
      <span *ngIf="paymentProviderMap[paymentMethod.paymentProvider.id]?.defaultPaymentMethod?.id === paymentMethod.id"> ({{ 'default' | translate }})</span>
    </td>
    <td>{{paymentMethod.code}}</td>
    <td>{{paymentMethod.paymentProvider.label}}</td>
    <td>{{paymentMethod.fixedMargin}}</td>
    <td>{{paymentMethod.percentageMargin}}</td>
    <td>{{paymentMethod.enabled }}</td>
    <td>
      <button *ngIf="!paymentMethod.deleted" class="btn btn-sm btn-link" (click)="editPaymentMethod(paymentMethod)">
        {{ 'Edit payment method' | translate }}
      </button>
    </td>
  </tr>
</table>

<ng-template #paymentMethodModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Edit payment method' | translate }} <img width="32" src="/assets/icons/payment/{{this.activePaymentMethod?.code}}.svg" /></h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="onEditPaymentMethodSubmit()"
      [formGroup]="paymentMethodForm"
      class="p-3"
    >
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Label' | translate }}</label>
            <input class="form-control" formControlName="label">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Fixed fee' | translate }}</label>
            <input class="form-control" formControlName="fixedMargin" type="number">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Percentage fee' | translate }}</label>
            <input class="form-control" formControlName="percentageMargin" type="number">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Allowed currencies' | translate }}</label>
            <ng-select
              formControlName="allowedCurrencies"
              [items]="currencyOptions$ | async"
              [clearable]="false"
              bindLabel="label"
              bindValue="value"
              [multiple]="true"
            ></ng-select>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input mt-2" type="checkbox" formControlName="enabled" id="enabled" />
            <label class="form-label form-check-label" for="enabled">{{ 'Enabled' | translate }}</label>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input mt-2" type="checkbox" formControlName="default" id="default" />
            <label class="form-label form-check-label" for="default">{{ 'Make default' | translate }}</label>
          </div>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingPaymentMethod$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
