<p class="fw-bold" *ngIf="headerText">{{ headerText }}</p>
<p class="text-muted small" *ngIf="!hideMessage">{{ 'Please select or enter an amount:' | translate }}</p>
<div class="row m-0 d-flex flex-wrap">
    <button
      *ngFor="let amount of [100, 250, 500, 1000, 2000]"
      (click)="setAmount(amount)"
      [class.active]="form.value.credits === amount && !amountVisible"
      [disabled]="(estimationLoadingLocal && !(amount === form.controls.credits.value && !amountVisible)) || !((selectedPaymentMethod?.maxAmount === null) || (selectedPaymentMethod?.maxAmount >= amount))"
      class="col m-1 btn btn-outline-primary"
    >
    <money [amount]="amount" [currency]="currency"></money>
  </button>
  <button (click)="showAmount()" class="col m-1 btn btn-outline-primary" [class.active]="amountVisible">
    <b><i class="far fa-plus"></i></b>
  </button>
</div>
<p *ngIf="filterPaymentMethods(paymentMethods$ | async).length > 1" class="text-muted small mt-3">{{ 'Please select your preferred payment method:' | translate }}</p>
<p *ngIf="filterPaymentMethods(paymentMethods$ | async).length == 1" class="text-muted small mt-3">{{ 'Payment method' | translate }}:</p>
<div class="row m-0 d-flex flex-wrap">
  <div class="col px-0">
    <button *ngFor="let paymentMethod of filterPaymentMethods(paymentMethods$ | async)"
    (click)="setPaymentMethod(paymentMethod)"
    [class.active]="form.value.paymentMethod === paymentMethod.id"
    class="m-1 btn btn-outline-primary"
    ngbTooltip="{{paymentMethod.label ?? paymentMethod.code}}"
    >
      <img height="32" src="/assets/icons/payment/{{paymentMethod.code}}.svg" />
    </button>
  </div>

</div>
<form class="mt-2" [formGroup]="form">
  <div class="mb-3" [hidden]="!amountVisible">
    <label>{{ 'Amount' | translate }}</label>
    <input type="number" min="100" [max]="selectedPaymentMethod?.maxAmount ? selectedPaymentMethod?.maxAmount : 750000" class="form-control"
      [ngClass]="{'is-invalid': form.controls['credits'].invalid &&
      form.controls['credits'].touched }"
      formControlName="credits">
    <div class="invalid-feedback">
      {{ 'This is not possible.' | translate }} {{ selectedPaymentMethod?.maxAmount ? ('(maximum amount: ' + selectedPaymentMethod?.maxAmount + ')') : '' }}
    </div>
  </div>
  <div class="mt-3 mb-2" *ngIf="payLater">
    <p class="text-muted small">{{ 'Please select your payment term:' | translate }}</p>
    <div class="row w-50 m-0 d-flex flex-wrap">
      <button class="m-1 col btn btn-outline-primary" (click)="changePayLaterDays(30)" [ngClass]="payLaterDays === 30 ? 'active' : ''" [disabled]="estimationLoadingLocal && !(payLaterDays === 30)">
        <div class="d-flex justify-content-center">
          30 days
        </div>
      </button>
      <button class="m-1 col btn btn-outline-primary" (click)="changePayLaterDays(60)" [ngClass]="payLaterDays === 60 ? 'active' : ''" [disabled]="estimationLoadingLocal && !(payLaterDays === 60)">
        <div class="d-flex justify-content-center">
          60 days
        </div>
      </button>
      <button class="m-1 col btn btn-outline-primary" (click)="changePayLaterDays(90)" [ngClass]="payLaterDays === 90 ? 'active' : ''" [disabled]="estimationLoadingLocal && !(payLaterDays === 90)">
        <div class="d-flex justify-content-center">
          90 days
        </div>
      </button>
    </div>
  </div>
  <div *ngIf="feeMessage$" class="row mb-2" style="width: 55%">
    <div class="col">
      <div class="info-message-container d-flex p-2 rounded-1">
        <i class="fa fa-spinner fa-spin mx-2 d-flex justify-content-center align-items-center" *ngIf="estimationLoadingLocal"></i>
        <i class="fal fa-info-circle mx-2 d-flex justify-content-center align-items-center" *ngIf="!estimationLoadingLocal"></i>
        <div class="small">{{ feeMessage$ | async }}</div>
      </div>
    </div>
  </div>
  <app-loading-button
    btnType="submit" [isDisabled]="form.invalid" [isLoading]="recharging" (ngClick)="onSubmit()"
  >
    {{ 'Top up your balance' | translate }}
  </app-loading-button>
</form>

