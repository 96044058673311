import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { WalletActions } from 'app/store/actions';

@Injectable()
export class WalletEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  requestRechargeWallet$ = createEffect(() => this.actions$.pipe(
    ofType(WalletActions.requestRechargeWallet),
    concatMap(body => this.api.post<{ url: string; }>({
      path: '/recharge_wallet',
      body: {...body, type: undefined},
    }).pipe(
      map(({ url }) => WalletActions.requestRechargeWalletSuccess({ url })),
      catchError(err => of(WalletActions.requestRechargeWalletFailure(err))),
    )),
  ));
}
